import { ApolloError, gql as GraphQL, useQuery, useMutation } from "@apollo/client";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const QueryGraphQL = GraphQL`
    query getDashboardPageQuery($id: String!, $type: String!, $locale: String!) {
        getProfile {
            _id
            settings {
                user {
                    username
                    discriminator
                    avatar
                }
            }
        }

        getGuild(id: $id, type: $type) {
            config {
                _id
                prefix
                data {
                    stats {
                        total {
                            members
                            messages
                            joins
                            leaves
                            commands
                        }
                        week {
                            timestamp
                            date
                            value {
                                members
                                messages
                                joins
                                leaves
                                commands
                            }
                        }
                    }
                    client {
                        nickname
                        ignore {
                            channels
                            roles
                        }
                    }
                    user {
                        id
                        level {
                            xp
                        }
                    }
                    auditLog {
                        id
                        username
                        changes {
                            id
                            other {
                                key
                                value
                            }
                            index
                        }
                        timestamp
                    }
                }
                settings {
                    language
                    leaderboard
                    commands
                    premium
                    core
                }
                modules 
            }

            guild {
                id
                owner_id
                icon
                name
                approximate_member_count
                emojis {
                    id
                    name
                    animated
                }
                channels {
                    id
                    name
                    type
                    parent_id
                }
                roles {
                    id
                    name
                    icon
                    color
                    permissions
                    managed
                    hoist
                    position
                }
                members
            }

            mutual {
                included {
                    id
                    name
                    icon
                }
            }
        }

        getCommand(locale: $locale) {
            id
            name
            description
        }
    }
`;

const MutationGraphQL = GraphQL`
    mutation setGuildMutation($id: String!, $config: String!, $type: String!) {
        setGuildConfig(id: $id, config: $config, type: $type) {
            _id
        }
    }
`;

const Query = (id: Snowflake, type: string, locale?: string): { loading: boolean; error: ApolloError | undefined; data: DashboardInterface.DashboardDataType | null; refetch: Function } => {
    const { loading, error, data, refetch } = useQuery(QueryGraphQL, { variables: { id, type, locale } });

    if (error) console.log(error);

    if (loading || error) return { loading: true, error, data: null, refetch };

    return { loading: false, error, data: { profile: data.getProfile, guild: data.getGuild.guild, config: data.getGuild.config, mutual: data.getGuild.mutual, commands: data.getCommand }, refetch };
};

const Mutation = () => useMutation(MutationGraphQL)[0];

const Hook = { Query, Mutation };

export default Hook;
