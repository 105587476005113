import Utility from "@utility@::@index";
import DOMPurify from "dompurify";

const urlPattern = /(?:http?:s:\/\/)?(?:\w+\.)+\w+(?:\/[^/\s]*)*/gim;
const urlPatternWithParts = /(?<url>(?:http?:s:\/\/)?(?<domain>(?:[\w-]+\.)+\w+)(?:\/[^/\s]*)*)/i;
const mentionRegex = /<(@[!&]?|#)(\d+)>/g;

export const InverseCase = (text: string, split: string = "", next = false): string => AlrernatingCase(text, split, next);
export const AlrernatingCase = (text: string, split: string = "", next = true): string =>
    text
        .split(split)
        .map((letter) => {
            next ? (next = false) : (next = true);
            return next ? letter.toLowerCase() : letter.toUpperCase();
        })
        .join(split);
export const CapitalizedCase = (text: string, split: string = " "): string =>
    text
        .split(split)
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
        .join(split);
export const SentenceCase = (text: string, split: string = " ", next: boolean = true) =>
    text
        .split(split)
        .map((word) => {
            next ? (word = `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`) : (word = word.toLowerCase());
            word.endsWith(".") || word.endsWith("!") || word.endsWith("?") ? (next = true) : (next = false);
            return word;
        })
        .join(split);

export const TrimString = (string: string, length: number): string => (string.length > length ? `${string.substr(0, length - 3)}...` : string);

export const LinkParser = (i: string | string[]): any | any[] => {
    if (typeof i == "string") {
        let match = i.match(/((?<protocol>https?):\/\/(?<domain>(?:[0-9a-z]{1,63}\.)*\w{2}[0-9a-z](?:[0-9a-z]\w{0,59})?\.(?:[a-z]{2,18}|xn--[0-9a-z-]))(?<path>(?:\/[^/\s]*)*))/gi);
        return match !== null && match.length < 255 && i.length < 1024 ? i : null;
    } else {
        return i.filter((element) => {
            let match = element.match(/((?<protocol>https?):\/\/(?<domain>(?:[0-9a-z]{1,63}\.)*\w{2}[0-9a-z](?:[0-9a-z]\w{0,59})?\.(?:[a-z]{2,18}|xn--[0-9a-z-]))(?<path>(?:\/[^/\s]*)*))/gi);
            return match !== null && match.length < 255 && element.length < 1024;
        });
    }
};

export const IsValidHttpUrl = (httpUrl: string) => {
    let url;
    try {
        url = new URL(httpUrl);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
};

export const EncodeHtmlEntities = (str: string) => str.replace(/[\u00A0-\u9999<>&]/g, (i: any) => "&#" + i.charCodeAt(0) + ";");
export const SanitizeHtml = (str: string): string => {
    const map: { [key: string]: string } = { "&": "&amp;", "<": "&lt;", ">": "&gt;", '"': "&quot;", "'": "&#039;" };
    return str.replace(/[&<>"']/g, (m) => map[m]);
};

export const SearchForURLs = (text: string) => text.match(urlPattern) ?? [];
export const ParseURL = (url: string) => urlPatternWithParts.exec(url)?.groups;

export const FormatDiscordText = (text: string, guild: any) => {
    text = FormatTextToDiscordMarkdown(text);
    text = FormatDiscordMentions(text, guild);
    return DOMPurify.sanitize(text);
};

export const FormatDiscordMentions = (text: string, guild: any): string => {
    let matches = text.match(mentionRegex);
    if (!matches) return text;

    for (let match of matches) {
        let type = match[2] === "&" ? "Role" : match[1] === "#" ? "Channel" : "User";
        let id = match.match(/\d+/)?.[0];
        let name = GetDiscordMentionNameFromId(type, id ?? "", guild);
        let cssClass = type.toLowerCase();
        let sanitizedName = EncodeHtmlEntities(name[0]);
        let mentionText = `<span class="discord-markdown mention ${cssClass}" ${name[1] ? `style="color: #${(name[1] as any).toString(16)}; background: #${(name[1] as any).toString(16)}1a;}"` : ""} data-mention-type="${type}" data-mention-id="${id}">${sanitizedName}</span>`;
        text = text.replace(match, mentionText);
    }

    return text;
};
export const FormatTextToDiscordMarkdown = (text: string): string => {
    const markdownRegex = {
        bold: /\*\*([\s\S]+?)\*\*(?!\*)/g,
        italic: /(^|[^*_])(\*|_)([^*_]+)\2(?!\*)/g,
        underline: /__([\s\S]+?)__(?!_)/g,
        strikethrough: /~~([\s\S]+?)~~(?!_)/g,
        inlineCode: /`([^`\n\r]+)`/g,
        multilineCode: /```([\s\S]*?)```/gm,
        blockquote: /(^|\n)> (.*)/g,
        hypertext: /\[([^\]]+)\]\(([^)]+)\)/g,
    };

    text = text.replace(markdownRegex.multilineCode, "<pre class='discord-markdown code-block'>$1</pre>");
    text = text.replace(markdownRegex.inlineCode, "<code class='discord-markdown inline-code'>$1</code>");

    text = text.replace(markdownRegex.hypertext, '<a href="$2" class="discord-markdown hypertext" rel="noopener noreferrer nofollow ugc" target="_blank">$1</a>');
    text = text.replace(markdownRegex.underline, "<u>$1</u>");
    text = text.replace(markdownRegex.bold, "<strong>$1</strong>");
    text = text.replace(markdownRegex.italic, "$1<em>$3</em>");
    text = text.replace(markdownRegex.strikethrough, "<del>$1</del>");

    text = text.replace(markdownRegex.blockquote, "<div class='flex'><div class='discord-markdown blockquote-bar'></div><blockquote class='discord-markdown blockquote'>$2</blockquote></div>");

    return text;
};
const GetDiscordMentionNameFromId = (type: string, id: string, guild: any): string[] => {
    let name = "";
    let color = null;
    let channelType = null;
    switch (type) {
        case "User": {
            if (!guild) {
                name = `@${type}`;
                break;
            }
            const member = guild.members.find((a: any) => a.user.id === id);
            name = "@" + (member ? (member?.nick ? member?.nick : member?.user?.username) : "Unknown User");
            break;
        }
        case "Role": {
            if (!guild) {
                name = `@${type}`;
                break;
            }
            const role = guild.roles.find((a: any) => a.id === id);
            color = role?.color;
            name = (role?.position === 0 ? "" : "@") + (role ? role.name : "Unknown Role");
            break;
        }
        case "Channel": {
            if (!guild) {
                name = `#${type}`;
                break;
            }
            const channel = guild.channels.find((a: any) => a.id === id);
            channelType = channel?.type;
            name = "#" + (channel ? channel.name : "Unknown Channel");
            break;
        }
    }
    return color ? [name, color] : channelType ? [name, channelType] : [name];
};

const RANDOM_TOKEN_SEARCH_PATTERN = /{\s*random\s*:(?<texts>[^}]+)}/gi;
export const SubstituteRandomStrings = (input: string): string => input.replace(RANDOM_TOKEN_SEARCH_PATTERN, (subStr, args) => Utility.RandomElement(args.split("/").map((str: string) => str.trim())));

export const GetGuildIconUrl = (id: Snowflake, icon: string): string => `https://cdn.discordapp.com/icons/${id}/${icon}.${icon.startsWith("a_") ? "gif" : "png"}`;
export const GetUserAvatarUrl = (id: Snowflake, avatar: string): string => `https://cdn.discordapp.com/avatars/${id}/${avatar}.${avatar.startsWith("a_") ? "gif" : "png"}`;
export const GetMemberAvatarUrl = (guildId: Snowflake, userId: Snowflake, avatar: string): string => `https://cdn.discordapp.com/guilds/${guildId}/users/${userId}/avatars/${avatar}.${avatar.startsWith("a_") ? "gif" : "png"}`;

export const SpliceSlice = (str: string, index: number, count: number, add: string) => {
    // We cannot pass negative indexes directly to the 2nd slicing operation.
    if (index < 0) {
        index = str.length + index;
        if (index < 0) {
            index = 0;
        }
    }

    return str.slice(0, index) + (add || "") + str.slice(index + count);
};
