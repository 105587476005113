import React, { useRef } from "react";
import Translate from "react-translate-component";
import Moment from "react-moment";
import Cookies from "js-cookie";
import { BiLogOutCircle, BiUser, BiCategory, BiDollar, BiDetail, BiBulb, BiBell } from "react-icons/bi";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";
import { FaDiscord } from "react-icons/fa";

interface UserBarProps {
    profile: IProfile;
    path?: string;
    utils: any;
}

const changeLog = [
    {
        ver: "1.2.4",
        timestamp: 1684117452000,
        details: (
            <>
                <div className="text title-8 blurple">Updated — q(≧▽≦q)</div>
                <div className="grid gap-2 mb-3">
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Auto Log</span> ~ Great news! We've made some improvements to the logging system, and also added new events and event groups to enhance the functionality. There is now <code>59</code> events!
                    </div>
                </div>
                <div className="text title-8 danger">Bug Fixes — (*/ω＼*)</div>
                <div className="grid gap-2 mb-3">
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text danger">◆</span> <span className="text font-2">Auto Log</span> ~ Fixed it where by messages would broken and the logging of member role update when it wasn't.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text danger">◆</span> <span className="text font-2">Self Role</span> ~ The button main menu would not be sent, this has been fixed and the messages should now be sent!
                    </div>
                </div>
                <div className="background-02 px-3 py-2 rounded-lg text text-center">
                    If you wish to report bugs you find, join our<> </>
                    <a href="https://discord.gg/j5pkCEff8P" target="_blank" rel="noreferrer">
                        discord server!
                    </a>
                    <br />
                    Thats all for now, hope you have fun!
                </div>
            </>
        ),
    },
    {
        ver: "1.2.0",
        timestamp: 1677326400000,
        details: (
            <>
                <div className="text title-8 success">New Features — ( •̀ ω •́ )✧</div>
                <div className="grid gap-2 mb-3">
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Moderation</span> ~ The moderation tab has been added to the server dashboard, you can currently go there to view what roles have access to configure the server dashboard, more moderative features will be added to this tab in future updates!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Auto Mod</span> ~ The auto moderation module has been added! It adds a layer of protection to your server with automated actions, you can set the actions you would prefer when a certain auto mod event is called, eg. <code>Warn</code> <code>Kick</code> etc - auto mod currently includes events such as <code>Flagged Links</code> <code>Discord Invites</code> etc. More cool events will be added in future updates!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Auto Log</span> ~ Need to quickly keep track of whats happening in your server? The auto log module logs the events that are happening within the server to specified channels, keep track of when changes are made within your server, eg. when a member is banned/kicked, when a channel is updated and so on!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Counting</span> ~ Fun with numbers! This is the counting module, it allows members with the server to count from <code>0</code> to the highest number possible. It includes counting in decimal, binary and hexadecimal as well as mathematical equations, its a fun way to pass the time with the server!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Cyclic Questions</span> ~ Need automated question of the day? Look no further; this module allows you to have questions sent into a channel every <code>Half-Day</code> <code>Day</code> or <code>Week</code> You may have it utilize threads for the responses to the questions and a role that is pinged when the question is asked!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text success">◆</span> <span className="text font-2">Message Preview</span> ~ This component allows you to preview discord messages right from the website without needing to send them to discord first, its currently being used around the dashboard, for example the <code>Greeter</code> and <code>Self Role</code> modules.
                    </div>
                </div>
                <div className="text title-8 blurple">Updated — q(≧▽≦q)</div>
                <div className="grid gap-2 mb-3">
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Greeter</span> ~ New variable! Use the <code>Random</code> variable to have a random option from the ones you specify be sent within the message whenever it is used. This new variable will spice up your welcome and farewell messages!
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Self Role</span> ~ The self role module now provides two sorts of selections: <code>Buttons</code> and <code>Select Menu</code>. It also includes four modes: <code>Normal</code> <code>Add</code> <code>Remove</code> and <code>Bind</code>. You can also update roles that have previously been added to a menu and define descriptions for the select menu roles.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Leveling</span> ~ When you set a reward role, you can now see the approximate time it would take to reach the level specified.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Role & Channel Select</span> ~ The roles and channel selectors now have icons and color dots, this makes it easier and faster to know the color of the role you're selecting and the type of channel you're selecting.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Leaderboard</span> ~ You can now reset a member's xp back to zero, as well as edit their level/xp to a custom amount.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text blurple">◆</span> <span className="text font-2">Commands</span> ~ The slash command names in Discord have been modified to remove the number that appears between the category and command name, making them simpler to read and discover. You may now see examples of how legacy commands are called when browsing commands on the <a href="/commands">commands page</a>. In addition, additional moderative commands such as <code>Warn</code> <code>Timeout</code> and <code>Temban</code> have been added.
                    </div>
                </div>
                <div className="text title-8 danger">Bug Fixes — (*/ω＼*)</div>
                <div className="grid gap-2 mb-3">
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text danger">◆</span> <span className="text font-2">Leveling</span> ~ If the current level's role is active, the bot should no longer repeatedly add and remove the prior role; it should also be a little faster as well.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text danger">◆</span> <span className="text font-2">Greeter</span> ~ Fixed the bug where the bot would sometimes send a message and sometimes wouldn't, it should now always send a message when a member/bot joins/leaves the server, please report to us if this bug still occurs.
                    </div>
                    <div className="background-01 px-3 py-2 rounded-lg text">
                        <span className="text danger">◆</span> <span className="text font-2">Server & User Icons</span> ~ Before, animated icons and avatars were static; this problem has now been resolved, and animated avatars and icons should now display properly.
                    </div>
                </div>
                <div className="background-02 px-3 py-2 rounded-lg text text-center">
                    If you wish to report bugs you find, join our<> </>
                    <a href="https://discord.gg/j5pkCEff8P" target="_blank" rel="noreferrer">
                        discord server!
                    </a>
                    <br />
                    Thats all for now, hope you have fun!
                </div>
            </>
        ),
    },
];

const ProfileMenu: React.FC<UserBarProps> = ({ profile, path, utils }) => {
    const version = Cookies.get("version");
    const shwoChangeLog = utils.use.SearchParams[0].get("change-log");

    const ref = useRef<any>();
    const changeLogPopup = React.useState(shwoChangeLog || (profile && version !== undefined && version !== AppData.project.ver));

    utils.use.ClickOutside(() => document.getElementById("profile-menu-menu")?.classList.remove("active"), ref);

    if (changeLogPopup[0]) Cookies.set("version", AppData.project.ver);

    return (
        <>
            <div ref={ref} id="profile-menu-menu" className="profile-menu">
                <AppComponent.Tooltip placement={"left"} content={profile ? `${profile.settings.user.username}#${profile.settings.user.discriminator}` : <Translate content="components.profile_menu.login" component="p" />}>
                    <div className="action" onClick={(element) => (profile ? element.currentTarget.parentElement?.classList.toggle("active") : (window.location.href = `${AppData.project.backendDomain}/api/auth/login`))}>
                        <img src={profile ? (profile.settings.user.avatar ? Utility.GetUserAvatarUrl(profile._id, profile.settings.user.avatar) + "?size=128" : `${process.env.PUBLIC_URL}/media/png/discord.png`) : `${process.env.PUBLIC_URL}/media/png/login.png`} alt="avatar" />
                        {profile && version !== AppData.project.ver && (
                            <span className="bg-red-500 rounded-full absolute top-0 right-0 text-white">
                                <BiBell className="w-[20px] h-[20px] p-[3px]" />
                            </span>
                        )}
                    </div>
                </AppComponent.Tooltip>
                {profile && (
                    <div className="menu">
                        <div className="profile">
                            <img src={profile.settings.user.avatar ? Utility.GetUserAvatarUrl(profile._id, profile.settings.user.avatar) + "?size=128" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="avatar" />
                            <div className="info">
                                <h1>{profile.settings.user.username}</h1>
                                <p>#{profile.settings.user.discriminator}</p>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <button className={`button ${path === "/dashboard" ? "active" : ""}`} onClick={() => utils.use.Navigate("/dashboard")}>
                                    <span className="icon" children={<BiCategory />} />
                                    <Translate content="components.profile_menu.b1" component="span" className="name" />
                                </button>
                            </li>
                            <li>
                                <button className={`button ${path === "/profile" ? "active" : ""}`} onClick={() => utils.use.Navigate("/profile")}>
                                    <span className="icon" children={<BiUser />} />
                                    <Translate content="components.profile_menu.b2" component="span" className="name" />
                                </button>
                            </li>
                            <li>
                                <button className={`button disabled ${path === "/economy" ? "active" : ""}`} onClick={() => utils.use.Navigate("/economy")}>
                                    <span className="icon" children={<BiDollar />} />
                                    <Translate content="components.profile_menu.b3" component="span" className="name" />
                                </button>
                            </li>
                            <li>
                                <button className={`button ${path === "/profile" ? "active" : ""}`} onClick={() => utils.use.Navigate("/profile?section=billing")}>
                                    <span className="icon" children={<BiDetail />} />
                                    <Translate content="components.profile_menu.b4" component="span" className="name" />
                                </button>
                            </li>
                            <div className="divider h-[1px] w-40 mx-auto my-1"></div>
                            <li>
                                <button className="button" onClick={() => changeLogPopup[1](true)}>
                                    <span
                                        className="icon relative"
                                        children={
                                            <>
                                                <BiBulb />
                                                {version !== AppData.project.ver && <span className="w-[12px] h-[12px] bg-red-500 rounded-full absolute top-[-3px] left-[-1px]"></span>}
                                            </>
                                        }
                                    />
                                    <Translate content="components.profile_menu.change" component="span" className="name" />
                                </button>
                            </li>
                            <div className="divider h-[1px] w-40 mx-auto my-1"></div>
                            <li>
                                <button className="button logout" onClick={() => (window.location.href = `${AppData.project.backendDomain}/api/auth/logout`)}>
                                    <span className="icon" children={<BiLogOutCircle />} />
                                    <Translate content="components.profile_menu.logout" component="span" className="name" />
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <AppComponent.PopUp id="ChangeLog" trigger={changeLogPopup} utils={utils} close closeFull>
                <div className="flex items-center justify-between">
                    <div className="text title-6-5">Change Log — v{changeLog[0].ver}</div>
                    <div className="button">
                        <button className="hover02 color-blurple full" onClick={() => window.open("https://discord.gg/j5pkCEff8P")}>
                            <span className="button-icon">
                                <FaDiscord />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="text subtitle-4 opacity-50">
                    <Moment date={changeLog[0].timestamp} format="LL" />
                </div>
                <div className="mb-3"></div>
                {changeLog[0].details}
            </AppComponent.PopUp>
        </>
    );
};

export default ProfileMenu;
