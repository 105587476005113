import React from "react";
import { BiChevronUp, BiCategory, BiRightArrow, BiCog, BiCheckShield, BiHash, BiCheck, BiMask, BiHappyBeaming, BiMedal, BiPlus, BiBarChartAlt2, BiCommentDots, BiBook, BiShieldQuarter, BiCube, BiPyramid, BiDetail, BiSearch, BiSearchAlt } from "react-icons/bi";
import { VscMegaphone } from "react-icons/vsc";
import Select from "react-select";
import Translate from "react-translate-component";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

export const Sidebar: React.FC<DashboardInterface.SidebarProps> = ({ utils, data, changeSection, activeSection, activeSectionName }) => {
    const serverSelectOptions = [
        ...data.mutual.included.map((guild: any) => ({
            label: (
                <span className="flex items-center gap-3">
                    <div className="server-image-box min-w-[30px] w-[30px] min-h-[30px] h-[30px] ">
                        <img className="rounded-[8px]" src={guild.icon ? Utility.GetGuildIconUrl(guild.id, guild.icon) + "?size=128" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                    </div>
                    <span className="name">{guild.name}</span>
                </span>
            ),
            value: guild.id,
            search: guild.name,
        })),
        {
            label: (
                <div className="button">
                    <button className="hover02 color-blurple full">
                        <Translate content="words.invite" className="button-text both" />
                        <span className="button-icon">
                            <BiPlus />
                        </span>
                    </button>
                </div>
            ),
            value: "0",
        },
    ];
    return (
        <nav className="sidebar closed">
            <header className="right-header px-[2rem] flex items-center dashboard gap-2 form">
                {/* <AppComponent.FormInput
                    className="content server-select select-menu-form max-w-[300px] search"
                    type="text"
                    htmlFor="dashboard-search"
                    placeholder={"Search the dashboard..."}
                    onChange={(value: any) => {}}
                    dark
                    utils={utils}
                    icon={
                        <div className="icon-left h-[35px] w-[35px]">
                            <div className="icon text-[20px]">
                                <BiSearchAlt />
                            </div>
                        </div>
                    }
                /> */}
                <AppComponent.FormInput className="content server-select select-menu-form max-w-[300px]" type="select" htmlFor="selected-server" value={serverSelectOptions.filter((a: any) => a.value === data.guild.id)} options={serverSelectOptions.filter((a: any) => a.value !== data.guild.id)} onChange={(value: any) => (![data.guild.id, "0"].includes(value.value) ? utils.use.Navigate(`/dashboard/${value.value}?section=${activeSectionName}`) : (window.location.href = AppData.project.client.invite2.replace(/{clientId}/g, AppData.project.client.id)))} dark utils={utils} />
            </header>
            <header className="left-header">
                <div className="image-text">
                    <div className="image">
                        <a href="/">
                            <img className="logo01" src={`${process.env.PUBLIC_URL}/media/svg/logo03.svg`} alt="logo" />
                            <img className="logo02" src={`${process.env.PUBLIC_URL}/media/svg/logo04.svg`} alt="logo" />
                        </a>
                    </div>
                </div>
                <BiRightArrow className="sidebar-toggle" />
            </header>
            <div className="menu-bar">
                <div className="menu">
                    <ul className="menu-links">
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-home" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiCategory />
                                </span>
                                <Translate content="routes.dashboard.home.name" component="span" className="sidebar-text nav-text" />
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-leaderboard" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiBarChartAlt2 />
                                </span>
                                <Translate content="routes.dashboard.leaderboard.name" component="span" className="sidebar-text nav-text" />
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-settings" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiCog />
                                </span>
                                <Translate content="routes.dashboard.settings.name" component="span" className="sidebar-text nav-text" />
                            </div>
                        </li>
                        {/* <li className="nav-link">
                            <div className="nav-button" id="dashboard-core" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiPlanet />
                                </span>
                                <Translate content="routes.dashboard.core.name" component="span" className="sidebar-text nav-text" />
                            </div>
                        </li> */}
                    </ul>
                </div>
                <div className="menu menu-01">
                    <li className="menu-text" onClick={() => document.querySelector(".menu.menu-01")?.classList.toggle("closed")}>
                        <Translate content="routes.dashboard.sidebar.management" component="span" className="sidebar-text" />
                        <span className="nav-icon">
                            <BiChevronUp />
                        </span>
                    </li>
                    <ul className="menu-links max-h-[150px]">
                        <li className="nav-link all-active">
                            <div className="nav-button" id="dashboard-moderation" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiCheckShield />
                                </span>
                                <Translate content="routes.dashboard.moderation.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    {/* <span className="tag drop-shadow-lg new text-[1.35rem]">
                                        <BiCube />
                                    </span> 
                                    <span className="tag drop-shadow-lg updated text-[1.35rem]">
                                        <BiPyramid />
                                    </span>*/}
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-automod" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiShieldQuarter />
                                </span>
                                <Translate content="routes.dashboard.automod.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-logging" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiBook />
                                </span>
                                <Translate content="routes.dashboard.logging.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="menu menu-02">
                    <li className="menu-text" onClick={() => document.querySelector(".menu.menu-02")?.classList.toggle("closed")}>
                        <Translate content="routes.dashboard.sidebar.entertainment" component="span" className="sidebar-text" />
                        <span className="nav-icon">
                            <BiChevronUp />
                        </span>
                    </li>
                    <ul className="menu-links  max-h-[100px]">
                        {/* <li className="nav-link">
                            <div className="nav-button" id="dashboard-chatbot" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiConversation />
                                </span>
                                <Translate content="routes.dashboard.chatbot.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li> */}
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-counting" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiHash />
                                </span>
                                <Translate content="routes.dashboard.counting.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-cyclicquestions" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiDetail />
                                </span>
                                <Translate content="routes.dashboard.cyclicquestions.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="menu menu-03">
                    <li className="menu-text" onClick={() => document.querySelector(".menu.menu-03")?.classList.toggle("closed")}>
                        <Translate content="routes.dashboard.sidebar.utility" component="span" className="sidebar-text" />
                        <span className="nav-icon">
                            <BiChevronUp />
                        </span>
                    </li>
                    <ul className="menu-links max-h-[250px]">
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-greeter" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiCommentDots />
                                </span>
                                <Translate content="routes.dashboard.greeter.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-selfrole" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiHappyBeaming />
                                </span>
                                <Translate content="routes.dashboard.selfrole.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-leveling" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiMedal />
                                </span>
                                <Translate content="routes.dashboard.leveling.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-autorole" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <BiMask />
                                </span>
                                <Translate content="routes.dashboard.autorole.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li className="nav-link">
                            <div className="nav-button" id="dashboard-autopublish" onClick={(data: any) => changeSection(data.target.id, activeSection)}>
                                <span className="nav-icon">
                                    <VscMegaphone />
                                </span>
                                <Translate content="routes.dashboard.autopublish.name" component="span" className="sidebar-text nav-text" />
                                <span className="sidebar-text nav-text tags flex">
                                    <span className="tag drop-shadow-lg active text-[1.35rem]">
                                        <BiCheck />
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="cover-button" onClick={() => document.querySelector(".sidebar")?.classList.add("closed")}></div>
        </nav>
    );
};
